import React from "react";
import { Button, Container } from "reactstrap";

// core components

function LandingPageHeader() {
  const handleGetInContact = () => {
    document.body.scrollBy(0, 5000); // For Safari
    document.documentElement.scrollBy(0, 5000); // For Chrome, Firefox, IE and Opera
  };
  let pageHeader = React.createRef();

  React.useEffect(() => {
    const updateScroll = () => {
      let windowScrollTop = window.pageYOffset / 3;
      pageHeader.current.style.transform =
        "translate3d(0," + windowScrollTop + "px,0)";
    };
    window.addEventListener("scroll", updateScroll);
    return function cleanup() {
      window.removeEventListener("scroll", updateScroll);
    };
  });

  return (
    <>
      <div className="page-header" data-parallax={true} ref={pageHeader}>
        <div className="filter">
          <video
            className="page-header-video"
            id="video"
            autoPlay
            loop
            muted
            playsInline
            src={require("assets/videos/snow.mp4")}
          />
        </div>
        <Container>
          <div className="motto text-center">
            <img
              alt="..."
              src={require("assets/images/whiteLogo.png")}
              style={{ height: "150px", marginBottom: "-35px" }}
            />
            <h1>Pride Appraisal</h1>
            <h3
              style={{
                maxWidth: "700px",
                margin: "20px auto",
                padding: "0 15px 15px"
              }}
            >
              Proudly serving the private insurance industry in South Eastern
              British Columbia for over 25 years.
            </h3>
            <Button
              className="btn-round"
              color="neutral"
              type="button"
              outline
              onClick={handleGetInContact}
            >
              Get in Contact
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
