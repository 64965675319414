import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon
} from "react-google-maps";

const options = {
  backgroundColor: "black",
  streetViewControl: false,
  styles: [
    {
      featureType: "administrative.country",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#6e746d"
        },
        {
          weight: 4
        }
      ]
    },
    {
      featureType: "administrative.locality",
      stylers: [
        {
          weight: 8
        }
      ]
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#000000"
        }
      ]
    },
    {
      featureType: "administrative.province",
      stylers: [
        {
          weight: 3
        }
      ]
    },
    {
      featureType: "administrative.province",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#6e746d"
        },
        {
          weight: 4
        }
      ]
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry.fill",
      stylers: [
        {
          lightness: 20
        }
      ]
    },
    {
      featureType: "landscape.natural",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "poi.business",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road",
      stylers: [
        {
          color: "#dad9dd"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road.arterial",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road.local",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off"
        }
      ]
    }
  ],
  fullscreenControlOptions: { position: 6 },
  disableDoubleClickZoom: false,
  mapTypeControl: false,
  zoomControl: false,
  clickableIcons: false,
  center: { lat: 49.75, lng: -115.76 },
  panControl: true,
  panControlOptions: { position: 4 }
};

const BaseMap = withScriptjs(
  withGoogleMap(props => {
    console.log(window.innerWidth);
    return (
      <>
        <GoogleMap
          options={options}
          defaultZoom={window.innerWidth < 1000 ? 7 : 8}
        >
          <Polygon
            options={{
              strokeOpacity: 0.2,
              fillOpacity: 0.1,
              fillColor: "blue"
            }}
            path={[
              { lat: 50.4, lng: -117.08 },
              { lat: 49.0, lng: -116.81 },
              { lat: 49.0, lng: -114.08 },
              { lat: 49.45, lng: -114.6 },
              { lat: 49.61, lng: -114.75 },
              { lat: 50.18, lng: -114.72 }
            ]}
          />
          {/* {props.markers &&
          props.markers.map((marker, index) => (
            <>
              <Marker
                key={index}
                onMouseOver={console.log}
                onClick={console.log}
                position={{ ...marker }}
              />
            </>
          ))} */}
        </GoogleMap>
      </>
    );
  })
);

export default BaseMap;
