import React from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import Map from "components/Map";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  let contactInfoPage = React.createRef();
  return (
    <>
      <ExamplesNavbar />
      <LandingPageHeader contactInfoPage={contactInfoPage} />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Let's talk service</h2>
                <h5 className="description">
                  We specialize in private insurance company claims involving
                  repair estimating and total loss documentation.
                </h5>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <img
                      alt="..."
                      src={require("assets/images/car.jpg")}
                      style={{ borderRadius: "50%", height: "200px" }}
                    />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Automotive Claims</h4>
                    <p className="description">
                      {/* Spend your time generating new ideas. You don't have to
                      think of implementing. */}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="">
                <div className="info">
                  <div className="icon icon-info">
                    <img
                      alt="..."
                      src={require("assets/images/rv.jpg")}
                      style={{ borderRadius: "50%", height: "200px" }}
                    />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Recreational Vehicles</h4>
                    <p>
                      {/* Whether it's Travel Trailers, Motorhomes or Truck Campers,
                      we can handle any claim.{" "} */}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <img
                      alt="..."
                      src={require("assets/images/snowmobile.jpg")}
                      style={{ borderRadius: "50%", height: "200px" }}
                    />
                  </div>
                  <div className="description">
                    <h4 className="info-title">
                      Motorcycles, ATVs and snowmobiles
                    </h4>
                    <p>
                      {/* Choose from a veriety of many colors resembling sugar
                      paper pastels. */}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Map />
        </div>
        <div className="section section-dark text-center">
          <Container>
            <h2 className="title">Who is Pride Appraisal?</h2>
            <Row>
              <Col md="4" style={{ margin: "auto" }}>
                <Card className="card-plain">
                  <div className="card-avatar">
                    <img
                      alt="..."
                      src={require("assets/images/dad.jpeg")}
                      style={{ borderRadius: "50%", height: "250px" }}
                    />
                  </div>
                  <CardBody>
                    <div className="author">
                      <CardTitle tag="h4">Brent Dewar</CardTitle>
                      <h6 className="card-category">Owner - Estimator</h6>
                    </div>
                    <p className="card-description text-center">
                      A family owned business, having worked with my father
                      before he retired; I have owned and operated Pride
                      Appraisal for more than 20 years.
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section landing-section" ref={contactInfoPage}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Contact Information</h2>
                <h4 className="text-center">
                  Phone: <a href="tel:1-250-489-3605">1-250-489-3605</a>{" "}
                </h4>
                <h4 className="text-center">
                  Email:{" "}
                  <a
                    href="mailto:prideappraisal@shaw.ca?Subject=Regarding%20a%20claim"
                    // target="_top"
                  >
                    prideappraisal@shaw.ca
                  </a>
                </h4>
                <h4 className="text-center">Address: 1719-15th Avenue South</h4>
                <h4 className="text-center">Cranbrook, B.C. V1C 4N5</h4>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <DemoFooter />
    </>
  );
}

export default LandingPage;
