import React from "react";
import BaseMap from "./BaseMap";

const Map = props => (
  <BaseMap
    googleMapURL={
      "https://maps.googleapis.com/maps/api/js?v=3.exp&key=" +
      process.env.REACT_APP_API_KEY +
      "&libraries=geometry,drawing,places"
    }
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: "calc(100vh - 71px)" }} />}
    mapElement={<div style={{ height: `100%` }} />}
  />
);

export default Map;
