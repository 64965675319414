import React from "react";
import classnames from "classnames";

import { NavbarBrand, Navbar, Container } from "reactstrap";

function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate" style={{ pointerEvents: "none" }}>
          <NavbarBrand
            data-placement="bottom"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <img
              alt="..."
              src={require("assets/images/greyLogo.png")}
              style={{
                height: "30px",
                paddingRight: "5px",
                paddingBottom: "2px"
              }}
            />
            Pride Appraisal
          </NavbarBrand>
        </div>
      </Container>
    </Navbar>
  );
}

export default ExamplesNavbar;
