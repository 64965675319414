/*eslint-disable*/
import React from "react";
import { Row, Container } from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          {/* <nav className="footer-nav">
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/in/andrew-dewar/"
                  target="_blank"
                >
                  Andrew's LinkedIn
                </a>
              </li>
            </ul>
          </nav> */}
          <div className="credits ml-auto">
            <span className="copyright">
              © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" /> by Pride Appraisal
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
